.write_a_review__container {
  #pr-write {
    .p-w-r {
      .form-group[data-reactid-powerreviews$='ag139398_msq74275'],
      .form-group[data-reactid-powerreviews$='ag140784_msq75332'] {
        display: none;
      }
    }
  }
}
