.ff-results {
  &__matches-sub-headline,
  &__regimen-steps {
    display: none;
  }
}

.page-footer {
  .footer {
    &-about-estee,
    &-need-help,
    &-customer-care {
      .footer-header {
        @media #{$medium-only} {
          background-image: $sprites-sprites;
          background-position-y: -3445px;
        }
      }
      &.is_open {
        .footer-header {
          @media #{$medium-only} {
            background-position-y: -3547px;
            background-image: $sprites-sprites;
          }
        }
      }
    }
  }
}

.ee-social-share__link {
  &.facebook,
  &.twitter,
  &.pinterest {
    @media #{$medium-up} {
      background-image: $sprites-sprites;
    }
  }
}

.el-search-block {
  &__product-results {
    display: flex;
    .product-result {
      position: relative;
      &__content {
        padding-bottom: 45px;
      }
      &__button {
        position: absolute;
        bottom: 0;
        left: 8%;
      }
    }
  }
}

.contact-us-page {
  .address-form {
    .form-item {
      @media #{$medium-up} {
        &.address-form {
          &__city-container {
            width: 100%;
            clear: both;
            float: none;
          }
        }
      }
    }
  }
}
#zero_dollar_overlay {
  h3 {
    text-align: center;
  }
  .popup_contents {
    text-align: center;
    .checkout-buttons,
    .shopping-buttons {
      display: inline-block;
      a {
        background-color: $color-navy;
        color: $color-white;
      }
    }
  }
}
.responsive-footer {
  .footer-main-menu {
    .LPMcontainer a {
      font-size: 21px !important;
    }
  }
}

.sidebar-page__content {
  @media #{$small-only} {
    .cs-quick-info {
      text-align: $ldirection;
      .cs-quick-info__block--live-chat {
        .LPMcontainer {
          margin: 0 !important;
          div {
            margin-#{$ldirection}: 0 !important;
            display: inline-block !important;
          }
        }
      }
    }
  }
}

.utility-nav__show-loyalty {
  .user-loyalty-state {
    .user-logged-in {
      display: none;
    }
  }
}
