.ui-widget-content {
  height: 12em;
  overflow-y: auto;
}

.clear {
  clear: both;
}

input[type='radio'] ~ label {
  margin-left: 1px;
}

.section-store-locator {
  &.device-mobile {
    .store-locator {
      .store-locator__nav .store-locator__nav-item.last {
        display: none;
      }
      .store-locator__nav .store-locator__nav-item.active {
        border: 0;
        float: none;
      }
    }
  }
}

[class*='-double-wear'] {
  .spp-product {
    &__skintype-select {
      display: none;
    }
  }
}

#signin {
  .page-header {
    position: relative;
  }
}

.sidebar-page {
  margin-top: 120px;
}

.account-mobile-landing-page {
  .account-landing-profile {
    margin-top: 120px;
  }
}

.checkout {
  overflow: initial;
}

.appt-book-page-header {
  margin-top: 120px;
}
