.victoria_beckham_waitlist {
  background-color: #000;
  /** Black Navigation **/
  .page-header:not(.is-alternate) .header-items {
    background: #000;
  }
  .page-header .el-mobile-search-block {
    background: #000;
  }
  &.device-mobile {
    .page-navigation__menu_text,
    :not(.is-alternate) .page-utilities .page-utilities__signin-text,
    :not(.is-alternate) .page-navigation .level-1 {
      color: #fff;
    }
    .page-header {
      &:not(.is-alternate) {
        .header-inner .page-branding {
          .page-branding__logo {
            background-size: auto auto;
            width: 187px;
            height: 26px;
            background: url('/media/boutiques/little-black-primer/images/estee_site_branding_sprites.png') 0 -1598px no-repeat;
          }
          .trust-mark-elc-nodeblock .trust_mark_header .trust_mark_header__link {
            background-image: url('/media/images/global/lo3_whilte.png');
          }
        }
      }
      &:not(.is-alternate) .page-utilities {
        .page-utilities__cart-icon {
          background-image: url('/media/images/background-images/background-image1.png');
          background-size: 24px 22px;
          width: 24px;
          height: 22px;
        }
        .page-utilities__search-icon {
          background-image: url('/media/images/background-images/background-image2.png');
          background-position: 0 0;
          width: 24px;
          height: 24px;
        }
      }
      .page-navigation__menu-icon {
        background-size: auto auto;
        background-position: 0 -3723px;
        height: 14px;
        width: 21px;
        background-image: url('/media/boutiques/little-black-primer/images/estee_site_branding_sprites.png');
        background-repeat: no-repeat;
      }
      .page-navigation__menu_text,
      .device-mobile :not(.is-alternate) .page-utilities .page-utilities__signin-text,
      .device-mobile :not(.is-alternate) .page-navigation .level-1 {
        color: #fff;
      }
    }
    /** End Black Navigation **/
    .signup-tout-wrapper {
      .signup-tout-content {
        width: 100%;
        .subtitle {
          margin-left: 0%;
          width: 90%;
          margin: 0 auto;
        }
        #email-signup__form {
          input.form-text {
            width: 100%;
            border: 1px solid #fff;
            color: #fff;
            background-color: #000;
            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: #fff;
              background-color: #000;
            }
            &::-moz-placeholder {
              /* Firefox 19+ */
              color: #fff;
              background-color: #000;
            }
            &:-ms-input-placeholder {
              /* IE 10+ */
              color: #fff;
              background-color: #000;
            }
            &:-moz-placeholder {
              /* Firefox 18- */
              color: #fff;
              background-color: #000;
            }
          }
          input.form-submit {
            background-color: #000;
            border: 1px #fff solid;
            width: 100%;
            line-height: 33px;
            margin-top: 15px;
            margin-left: 0;
            display: block;
            height: 43px;
          }
        }
        .footer-social-links {
          margin: 0 auto;
          .twitter {
            background-position: 0px -1333px;
          }
          .facebook {
            background-position: 0px -1063px;
          }
          .pinterest {
            background-position: 0px -1243px;
          }
          .instagram {
            background-position: 0px -1150px;
          }
          .youtube {
            background-position: 0px -1419px;
          }
        }
      }
      .thank_you_text {
        color: #fff;
        text-align: center;
        margin: 0 auto 50px auto;
      }
    }
  }
  .error {
    color: #fff;
    margin: 10px auto;
    text-align: left;
    width: 100%;
  }
}

.page-products,
.page-product {
  &.device-mobile {
    #colorbox {
      #cboxContent {
        #cboxLoadedContent {
          padding-#{$rdirection}: 20px;
          iframe {
            width: 100%;
            height: 100%;
          }
        }
        #cboxClose {
          #{$rdirection}: 10px;
          top: 10px;
          background-position: -4px -977px;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
