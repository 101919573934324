.section-store-locator {
  .store-locator {
    .local-search-form {
      &__city-state-wrapper {
        @media #{$small-only} {
          float: none;
        }
        float: left;
      }
    }
  }
}
