$color-sky-blue: #d0e4f5;

.tracking-wrapper {
  position: relative;
  margin: 0 auto 50px auto;
  max-width: 480px;
  padding: 20px 10px 0 10px;
  .headline--page {
    margin: 0px;
    font-size: 40px;
    line-height: 45px;
    color: $color-black;
    text-align: left;
    border-bottom: none;
    padding-bottom: 0px;
  }
  .headline--section {
    border: none;
  }
  .order-summary {
    .headline--section {
      font-size: 22px;
      padding: 10px 0;
      color: $color-black;
      // border-top: 1px solid $color-light-gray;
      margin: 0;
    }
    &__item {
      margin: 5px 0 15px 0;
      width: 100%;
      .tracking_hdr {
        font-weight: bold;
        float: left;
        width: 165px;
      }
      .tracking_val {
        float: left;
        width: 180px;
      }
      &--broadcast_msg {
        margin-top: 20px;
      }
      &--status_meaning {
        margin-bottom: 30px;
        .tracking_hdr {
          clear: both;
          padding: 0 0 15px 0;
        }
        .tracking_val {
          clear: both;
          width: 100%;
        }
      }
      &--no_order_found {
        color: $color-red;
      }
      &--tracking_status {
        .tracking_hdr {
          clear: both;
          padding: 0 0 15px 0;
        }
        .tracking_val {
          clear: both;
          width: 100%;
        }
      }
      .tracking_imgs {
        &.current_status_0 {
          img {
            &.img_status_1,
            &.img_status_2 {
              opacity: 0.4;
            }
          }
        }
        &.current_status_1 {
          img {
            &.img_status_2 {
              opacity: 0.4;
            }
          }
        }
        &.current_status_9 {
          img {
            &.img_status_0,
            &.img_status_1,
            &.img_status_2 {
              opacity: 0.4;
            }
          }
        }
      }
      .divTable {
        display: table;
        &.trackingTable {
          border: 1px solid $color-light-gray;
          width: 100%;
          text-align: left;
          border-collapse: collapse;
        }
        .divTableHeading {
          display: table-header-group;
          background: $color-black;
          border-bottom: 0px solid $color-black;
          .divTableRow {
            display: table-row;
            .divTableHead {
              display: table-cell;
              border: 1px solid $color-light-gray;
              padding: 3px 4px;
              font-size: 15px;
              font-weight: bold;
              color: $color-white;
              text-align: center;
              border-left: 0px solid $color-sky-blue;
            }
            .divTableHead:first-child {
              border-left: none;
            }
          }
          .divTableRow:nth-child(even) {
            background: $color-white;
          }
        }
        .divTableBody {
          display: table-row-group;
          .divTableRow {
            display: table-row;
            .divTableCell {
              display: table-cell;
              border: 1px solid $color-light-gray;
              padding: 3px 4px;
              text-align: center;
            }
          }
        }
      }
    }
    .headline-top {
      padding-top: 20px;
      border: none;
    }
  }
  #where-is-my_order {
    // border-top: 1px solid $color-light-gray;
    .headline-bottom {
      .headline--section {
        margin-top: 25px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        border: none;
      }
    }
  }
  &.is_mobile_1 {
    .tracking_imgs {
      img {
        width: 355px;
        height: auto;
        display: block;
      }
    }
    input {
      padding: 0 10px;
      width: 100%;
      height: 37px;
      margin: 5px 0 10px 0;
      float: left;
    }
    .tracking_hdr {
      text-transform: uppercase;
    }
  }
  &.is_mobile_0 {
    max-width: 960px;
    padding: 35px 0 0 0;
    .order-summary {
      &__item {
        .tracking_imgs {
          img {
            float: left;
          }
        }
        .tracking_hdr {
          width: 200px;
          text-transform: uppercase;
        }
        .tracking_val {
          width: 740px;
        }
        &--status_meaning,
        &--tracking_status {
          .tracking_hdr {
            padding: 0;
            clear: none;
          }
          .tracking_val {
            clear: none;
            width: 740px;
          }
        }
      }
    }
    input {
      width: 240px;
      float: left;
      margin: 5px 0 10px 0;
      padding: 0 10px;
      height: 37px;
    }
  }
}
