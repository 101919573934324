.victoria_beckham_waitlist {
  .signup-tout-wrapper {
    display: block;
    margin: 0 auto 30px;
    max-width: 1066px;
    position: relative;
  }
  .signup-tout-wrapper .signup-tout-background-image img {
    width: 100%;
  }
  .signup-tout-wrapper .signup-tout-content {
    position: absolute;
    width: 55%;
  }
  .signup-tout-wrapper .signup-tout-content.dark {
    color: #040a2b;
  }
  .signup-tout-wrapper .signup-tout-content.light {
    color: #fff;
  }
  .signup-tout-wrapper .signup-tout-content .eyebrow {
    font-family: 'AkzidenzGrotesk W1G';
    font-size: 15px;
    display: block;
    margin-left: 10%;
    letter-spacing: 0.2em;
    font-weight: bold;
  }
  .signup-tout-wrapper .signup-tout-content .headline1,
  .signup-tout-wrapper .signup-tout-content .headline2 {
    font-family: 'OptimaDisplayLight';
    font-size: 98px;
    line-height: 98px;
    display: block;
    letter-spacing: -5px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .signup-tout-wrapper .signup-tout-content .headline1,
    .signup-tout-wrapper .signup-tout-content .headline2 {
      font-size: 51px;
      font-size: 6vm;
      font-size: 6vmin;
      line-height: 43px;
      line-height: 5vm;
      line-height: 5vmin;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 768px) {
    .signup-tout-wrapper .signup-tout-content .headline1,
    .signup-tout-wrapper .signup-tout-content .headline2 {
      font-size: 51px;
      font-size: 6vm;
      font-size: 6vmin;
      line-height: 43px;
      line-height: 5vm;
      line-height: 5vmin;
    }
  }
  @media only screen and (max-width: 640px) {
    .signup-tout-wrapper .signup-tout-content .headline1,
    .signup-tout-wrapper .signup-tout-content .headline2 {
      font-size: 20px;
      font-size: 6vm;
      font-size: 6vmin;
      line-height: 16px;
      line-height: 5vm;
      line-height: 5vmin;
    }
  }
  .signup-tout-wrapper .signup-tout-content .headline2 {
    margin-left: 10%;
  }
  .signup-tout-wrapper .signup-tout-content .subtitle {
    font-family: 'AkzidenzGrotesk W1G';
    font-size: 15px;
    display: block;
    margin: 2% 0 0 10%;
    letter-spacing: 0.2em;
  }
  .signup-tout-wrapper .signup-tout-content #email-signup__form {
    margin-left: 10%;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .signup-tout-wrapper .signup-tout-content #email-signup__form input.form-text {
    width: 255px;
    border: 1px solid #ccc;
    color: #05082b;
  }
  .signup-tout-wrapper .signup-tout-content #email-signup__form input.form-text::-webkit-input-placeholder {
    color: #05082b;
  }
  .signup-tout-wrapper .signup-tout-content #email-signup__form input.form-text:-moz-placeholder {
    color: #05082b;
    opacity: 1;
  }
  .signup-tout-wrapper .signup-tout-content #email-signup__form input.form-text::-moz-placeholder {
    color: #05082b;
    opacity: 1;
  }
  .signup-tout-wrapper .signup-tout-content #email-signup__form input.form-text:-ms-input-placeholder {
    color: #05082b;
  }
  .signup-tout-wrapper .signup-tout-content #email-signup__form input.form-submit {
    height: 33px;
    line-height: 33px;
    margin-top: -2px;
    margin-left: 15px;
    padding: 0 20px;
  }
  .signup-tout-wrapper .signup-tout-content.light #email-signup__form input.form-submit {
    border: 1px solid #fff;
  }
  .signup-tout-wrapper .signup-tout-content .thank_you_text {
    font-family: 'AkzidenzGrotesk W1G';
    font-size: 15px;
    display: block;
    margin-left: 10%;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .signup-tout-wrapper .footer-social-links {
    margin-left: 10%;
  }
  .signup-tout-wrapper .footer-social-links li {
    background-size: auto auto;
    height: 24px;
  }
  .signup-tout-wrapper .footer-social-links .facebook {
    width: 13px;
  }
  .signup-tout-wrapper .footer-social-links .twitter {
    width: 26px;
    margin: 0 0 0 16px;
  }
  .signup-tout-wrapper .footer-social-links .pinterest {
    width: 25px;
    margin: 0 0 0 17px;
  }
  .signup-tout-wrapper .footer-social-links .instagram {
    width: 23px;
    .signup-tout-wrapper {
      display: block;
      margin: 0 auto 30px;
      max-width: 1066px;
      position: relative;
      .signup-tout-background-image img {
        width: 100%;
      }
      .signup-tout-content {
        position: absolute;
        width: 55%;
        &.dark {
          color: #040a2b;
        }
        &.light {
          color: #fff;
          #email-signup__form input.form-submit {
            border: 1px solid #fff;
          }
        }
        .eyebrow {
          font-family: 'AkzidenzGrotesk W1G';
          font-size: 15px;
          display: block;
          margin-left: 10%;
          letter-spacing: 0.2em;
          font-weight: bold;
        }
        .headline1,
        .headline2 {
          font-family: 'OptimaDisplayLight';
          font-size: 98px;
          line-height: 98px;
          display: block;
          letter-spacing: -5px;
        }
        .headline2 {
          margin-left: 10%;
        }
        .subtitle {
          font-family: 'AkzidenzGrotesk W1G';
          font-size: 15px;
          display: block;
          margin: 2% 0 0 10%;
          letter-spacing: 0.2em;
        }
        #email-signup__form {
          margin-left: 10%;
          margin-top: 30px;
          margin-bottom: 20px;
          input.form-text {
            width: 255px;
            border: 1px solid #ccc;
            color: #05082b;
            &::-webkit-input-placeholder {
              color: #05082b;
            }
            &:-moz-placeholder {
              color: #05082b;
              opacity: 1;
            }
            &::-moz-placeholder {
              color: #05082b;
              opacity: 1;
            }
            &:-ms-input-placeholder {
              color: #05082b;
            }
          }
          input.form-submit {
            height: 33px;
            line-height: 33px;
            margin-top: -2px;
            margin-left: 15px;
            padding: 0 20px;
          }
        }
        .thank_you_text {
          font-family: 'AkzidenzGrotesk W1G';
          font-size: 15px;
          display: block;
          margin-left: 10%;
          margin-top: 30px;
          margin-bottom: 20px;
        }
      }
      .footer-social-links {
        margin-left: 10%;
        li {
          background-size: auto auto;
          height: 24px;
        }
        .facebook {
          background-position: 0 -2957px;
          width: 13px;
        }
        .twitter {
          background-position: 0 -9842px;
          width: 26px;
          margin: 0 0 0 16px;
        }
        .pinterest {
          background-position: 0 -5586px;
          width: 25px;
          margin: 0 0 0 17px;
        }
        .footer-social-links .youtube {
          width: 20px;
          margin: 0 0 0 19px;
        }
      }
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      .signup-tout-wrapper .signup-tout-content {
        .headline1,
        .signup-tout-wrapper .signup-tout-content .headline2 {
          font-size: 51px;
          font-size: 6vm;
          font-size: 6vmin;
          line-height: 43px;
          line-height: 5vm;
          line-height: 5vmin;
        }
      }
    }
    @media only screen and (min-width: 641px) and (max-width: 768px) {
      .signup-tout-wrapper .signup-tout-content {
        .headline1,
        .signup-tout-wrapper .signup-tout-content .headline2 {
          font-size: 51px;
          font-size: 6vm;
          font-size: 6vmin;
          line-height: 43px;
          line-height: 5vm;
          line-height: 5vmin;
        }
      }
    }
    @media only screen and (max-width: 640px) {
      .signup-tout-wrapper .signup-tout-content {
        .headline1,
        .signup-tout-wrapper .signup-tout-content .headline2 {
          font-size: 20px;
          font-size: 6vm;
          font-size: 6vmin;
          line-height: 16px;
          line-height: 5vm;
          line-height: 5vmin;
        }
      }
    }
    margin: 0 0 0 18px;
  }
  .signup-tout-wrapper .footer-social-links .youtube {
    width: 20px;
    margin: 0 0 0 19px;
  }
}

.tiny-waitlist-overlay {
  .email_input {
    input[type='email'],
    input[type='submit'] {
      display: block;
      margin: 10px 0;
    }
    input[type='email'] {
      width: 65%;
    }
  }
}
