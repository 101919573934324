// wishlist-confirm overlay
.colorbox__wishlist-confirm {
  #cboxContent {
    height: auto !important;
    #cboxLoadedContent {
      height: auto !important;
      .wishlist-confirm__container {
        margin-top: 30px;
      }
    }
  }
}

#order_detail .shipment {
  padding: 0 25px;
}

#order_detail .shipment div.order-details--cart {
  padding: 0;
}

.trust_mark_footer {
  margin-left: 0px;
  margin-right: 0px;
  border-top: 1px solid $color-medium-gray;
  padding-top: 10px;
  padding-bottom: 10px;
  .trust_mark_footer__link {
    width: 128px;
    height: 145px;
    background-image: url('/media/images/global/lo2-white.png');
    background-repeat: no-repeat;
    text-indent: -9999px;
    display: block;
    margin: auto;
  }
}

.quickshop-inline__phone-button,
.spp__phone-button {
  display: none;
}

#email-signup__form .form-submit {
  margin-bottom: 12px;
}

.email-signup {
  &__success-text {
    min-height: 100px;
  }
  input[type='tel'] {
    margin: 15px 0;
    width: 100%;
  }
}

.mobile-search-page {
  #search-results {
    #search-help {
      .search-help__content {
        a.contact-us-link {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) and (orientation: portrait) {
  #cboxLoadedContent {
    .email-signup__success-header {
      width: 280px;
      margin: 0;
    }
    .email-signup__success-text {
      width: 280px;
      margin: 0;
    }
  }
}

@media only screen and (min-width: 361px) and (orientation: landscape) {
  #cboxLoadedContent {
    .email-signup__success-header {
      margin-bottom: 0;
    }
    .email-signup__success-text {
      margin: 1px 0 0 0;
    }
  }
}

#holiday-2015_container.mobile-landing {
  min-height: 0 !important;
  .propoContainerReady {
    height: 1250px !important;
    .ribbon-bg {
      top: 35%;
    }
  }
}

.address-form__fieldset .address-form__address1-container.full {
  padding-left: 0;
}

.address-form__fieldset .address-form__address2-container.full {
  padding-left: 0;
}

div.nodeblock-signup-form.promotion-signup-form {
  padding: 5%;
  width: auto;
  .email-signup__section {
    width: 100%;
  }
  p.email_signup__header {
    font-size: 15px !important;
    font-family: 'AkzidenzGrotesk W1G';
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    line-height: 24px;
    sup {
      top: 0;
    }
  }
  p.submit_body {
    padding: 0;
  }
  .singup_form_social {
    a.facebook {
      background-position: 0 -1104px;
    }
    a.twitter {
      background-position: 0 -1374px;
    }
    a.youtube {
      background-position: 0 -1465px;
    }
    a.pinterest {
      background-position: 0 -1288px;
    }
    a.instagram {
      background-position: 0 -1195px;
    }
  }
}

.offer__kit__formatter {
  .everyone_gwp {
    li {
      list-style: none;
      padding: 0 10px;
      text-indent: -20px;
    }
    li::before {
      content: '\2022';
      padding-right: 10px;
    }
  }
  .offer__kit__option {
    float: left;
    width: 33.3%;
  }
}

.viewcart {
  .wp-messages {
    color: $color-red;
  }
  .wp-messages a {
    color: $color-red;
  }
}

body[data-checkout-release='1'] {
  .cart-item-engraving {
    .engraving {
      &-delete {
        margin-#{$rdirection}: 0;
      }
    }
  }
  .engraving-view {
    border-top: 0;
    padding: 0;
  }
}

body.section-victoria-beckham {
  .quickshop-inline {
    .smoosh-list {
      display: none;
    }
  }
  .pre-order_status_display {
    max-height: 36px;
    height: auto !important;
  }
}

.olapic-carousel-list-container .olapic-carousel-partial-container {
  height: 136px;
  line-height: 136px;
  background: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_MOBILE_dollar.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0;
}

.spp {
  .product-full {
    &__price-installment {
      .afterpay-content,
      .afterpay-content-mb {
        display: none;
      }
      .afterpay_logo {
        width: 100px;
        padding-bottom: 5px;
        background: url('/media/export/cms/afterpay/mobile/ap-logo.png') no-repeat;
        height: 25px;
        display: inline-block;
        background-size: 100px;
        vertical-align: middle;
      }
      a {
        margin-left: 30px;
        font-size: 12px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        text-decoration: underline;
        cursor: pointer;
        font-weight: bold;
      }
      margin-bottom: 10px;
    }
  }
  .spp-product {
    &__price-size-select-container {
      margin-bottom: 11px;
    }
  }
}

.sp19-ppro {
  &__mod2-prodhovs,
  &__mod2-prodwrapper {
    margin-top: 9.65%;
    left: 0;
    margin-left: 10%;
  }
}

.mpp-header-sample-mini-bar-elc-nodeblock {
  .hero-tout-mobile {
    &__content {
      &--a {
        min-height: 400px;
      }
    }
  }
}

.p-w-r {
  .pr-review-display {
    .pr-rd-footer {
      .pr-modal-visible {
        &.pr-modal {
          top: 95px;
          transform: translateY(0);
        }
      }
    }
  }
}

.page-footer {
  z-index: 99;
}

.account-favorites {
  &__list {
    > li {
      padding-bottom: 65px;
      position: relative;
      .sku-brief-editorial__bottom {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

div#colorbox {
  #cboxLoadedContent {
    .overlay-link {
      text-decoration: underline;
    }
  }
}

.contact-us-page {
  .address-form {
    .form-item {
      input[type='text'],
      input[type='email'],
      .selectbox {
        width: 100%;
        display: block;
      }
    }
  }
  .contact-form {
    &__order-number {
      color: $color-navy;
    }
  }
}

.customer-service {
  &--faqs {
    #customer_service_chat_2 {
      display: none;
    }
  }
}

.sidebar-page__content {
  @media #{$small-only} {
    .cs-quick-info {
      text-align: $ldirection;
      .cs-quick-info__block--live-chat {
        .LPMcontainer div {
          margin-#{$ldirection}: 0 !important;
          display: inline-block !important;
        }
      }
    }
  }
}

.spp-engraving {
  .engraving-cat-571,
  .engraving-cat-28371 {
    .engraving-form-choice-43 {
      .engrave-placement {
        top: auto;
        left: 0;
      }
    }
  }
  .engraving-cat-571 {
    .engraving-form-choice-43 {
      .engrave-placement {
        bottom: 183px;
      }
    }
  }
  .engraving-cat-28371 {
    .engraving-form-choice-43 {
      .engrave-placement {
        bottom: 150px;
      }
    }
  }
}

#zero_dollar_overlay {
  h3 {
    text-align: center;
  }
  .popup_contents {
    text-align: center;
    .checkout-buttons,
    .shopping-buttons {
      display: inline-block;
      a {
        background-color: $color-navy;
        color: $color-white;
      }
    }
  }
}
.re-nutriv-landing-page-page {
  .content-block-tout__cta {
    text-align: center;
  }
}

.vto-skin-care-lux ~ .elc-vto-skin-care {
  .elc-skin-care-result-blocks {
    .sd-product-grid .elc-vto-skincare-product-grid .elc-product-brief {
      .elc-clickable-wrapper.elc-rating-component-container {
        margin-bottom: 10px;
        display: block;
      }
      .elc-size-picker-container.multiple-sizes {
        margin-top: 15px;
      }
    }
  }
}
