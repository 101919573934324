@if $password_strengthen {
  .sign-in-page {
    .form-item {
      &.password-field {
        overflow: visible;
      }
    }
  }
  .registration-page {
    &__content {
      .profile-info {
        &__change_pwd_link {
          margin: 13px 0 20px;
        }
      }
    }
  }
  .password-field {
    &__info {
      &-checkout {
        #{$ldirection}: 42%;
        -webkit-transform: translateY(5%);
        -moz-transform: translateY(5%);
        -o-transform: translateY(5%);
        transform: translateY(5%);
        margin-top: 5px;
        @media #{$medium-up} {
          -webkit-transform: translateY(-74%);
          -moz-transform: translateY(-74%);
          -o-transform: translateY(-74%);
          transform: translateY(-74%);
          position: absolute;
          width: 200px;
          #{$rdirection}: 42%;
          border: 1px solid $color-black;
          &::before {
            content: '';
            position: absolute;
            top: 43px;
            #{$rdirection}: 100%;
            border: 5px solid;
            border-color: transparent $color-dark-gray transparent transparent;
          }
        }
      }
      #confirm & {
        @media #{$medium-up} {
          top: 48px;
          width: 200px;
        }
      }
      #password_reset & {
        @media #{$medium-up} {
          top: -13px;
        }
      }
    }
    &__rules {
      font-size: 10px;
      @media #{$medium-up} {
        font-size: 12px;
      }
      .checkout & {
        @media #{$medium-up} {
          column-count: 1;
        }
      }
    }
  }
}
