div {
  &.engraving-cart-control {
    margin-top: 20px;
  }
  &.engraving-wrapper {
    font-size: 12px;
    clear: both;
    padding-top: 14px;
    h3 {
      font-size: 25px;
      font-family: 'Optima Display Roman';
    }
    div.engraving {
      background: $color-gray-cs-side;
      padding: 1em 0;
      clear: both;
      height: 105px;
    }
    &.mobile div.engraving {
      height: 180px;
    }
    &.pc div.engraving-row div.engraving-column {
      height: 15px;
    }
    &.mobile div.engraving div.engraving-view-title {
      width: 75%;
      float: left;
      margin-bottom: 25px;
    }
    &.pc div.engraving div.engraving-view-title {
      width: 30%;
      text-align: right;
      height: 80px;
    }
    div.engraving div.engraving-view-title-top {
      margin-right: 10px;
      color: $color-dark-gray;
    }
    &.pc div.engraving div.engraving-view-title-top {
      height: 65px;
    }
    div.engraving div {
      &.engraving-view-title-bottom {
        margin-right: 16px;
      }
      &.engraving-view-message {
        text-align: center;
        cursor: pointer;
        float: left;
      }
    }
    &.mobile div.engraving div.engraving-view-message {
      width: 100%;
      clear: both;
    }
    .engraving {
      &.engraving-view-message-content {
        overflow: auto;
        overflow-y: hidden;
        margin-top: -15px;
        color: $color-dark-gray;
        &.engraving-script {
          font-family: $script;
        }
        &.engraving-optima {
          font-family: $optimalight;
        }
        &.engraving-roman {
          font-family: $roman;
        }
        div {
          white-space: nowrap;
          word-wrap: normal;
          font-size: 16px;
          line-height: 50px !important;
        }
      }
      &.engraving-view-message a.engraving-toggle-edit {
        padding: 0px 10px;
        background-color: $color-gray-cs-callout;
        position: relative;
        top: -8px;
        width: 100px;
      }
      &.engraving-view-control {
        text-align: right;
        float: right;
        letter-spacing: 0.2em;
        .engraving-view {
          &__message-edit {
            text-decoration: underline;
          }
          &__message-delete {
            border-#{$ldirection}: 1px solid $color-navy;
            padding-#{$ldirection}: 11px;
            margin-#{$ldirection}: 5px;
            text-decoration: underline;
          }
          &__message-cta {
            text-transform: uppercase;
          }
        }
      }
    }
    &.mobile div.engraving div.engraving-view-control {
      text-align: right;
      width: 25%;
      float: left;
    }
    div.engraving div.engraving-view-control a.engraving-delete {
      text-decoration: none;
      font-style: italic;
      color: $color-lighter-gray-alt;
      margin-left: 10px;
    }
    &.mobile div.engraving-form-element-footer {
      margin-top: 10px;
    }
    &.pc div.engraving-form-element-footer {
      float: right;
    }
    div {
      &.engraving-form-element-footer .engraving-cancel {
        text-decoration: underline;
      }
      &.engraving-edit {
        input,
        select {
          width: 194px;
        }
      }
    }
    .engraving-form-element {
      padding: 5px 0px;
      .engraving-label {
        border: 1px solid $color-light-gray;
        background-color: $color-white;
        color: $color-light-gray;
        border-radius: 6px;
        padding: 0.35em 0.7em;
        display: inline-block;
        cursor: pointer;
        &.engraving-selected {
          border-color: $color-black;
          color: $color-black;
          cursor: default;
        }
      }
    }
    .engraving-create {
      font-size: 12px;
      border-bottom: 1px solid $color-light-gray;
      border-top: 1px solid $color-light-gray;
      clear: both;
      padding: 7px 0 10px 0;
    }
    .engraving-title {
      margin-bottom: 8px;
      clear: both;
      font-weight: 700;
    }
  }
}

.right.checkout__sidebar {
  .engraving-wrapper.pc {
    .engraving {
      .engraving-view-message-content div {
        font-size: 20px;
        overflow: hidden;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .checkout {
    .cart-items {
      border-bottom: none;
      .cart-item {
        border-bottom: none;
        clear: both;
      }
    }
  }
  div.engraving-wrapper.pc {
    div.engraving-form-element-footer {
      float: left;
      margin: 10px 0;
    }
    div.engraving {
      div .engraving-view-message-content {
        div {
          font-size: 18px;
        }
      }
      float: left;
      height: auto;
      .engraving-view {
        border-top: 0;
        padding: 0;
      }
    }
    .engraving-create {
      height: 94px;
    }
    .engraving-row {
      .engraving-view-message {
        &.engraving-column {
          height: 25px;
        }
      }
    }
  }
}

#index {
  .right.checkout__sidebar {
    div.engraving {
      height: 80px;
      .engraving-column {
        float: left;
      }
      .engraving-view-title {
        width: 38%;
        text-align: left;
        height: 50px;
      }
      .engraving-view-message {
        height: 50px;
        width: 62%;
        border: 1px solid $color-light-gray;
        .engraving-toggle-edit {
          display: none;
        }
      }
      .engraving-view-control {
        display: none;
      }
      .engraving-view {
        &__message-content {
          margin-top: 14px;
          font-size: 16px;
          &.Script {
            margin-right: 5px;
          }
          &.Optima {
            font-size: 1.2em;
          }
        }
      }
    }
  }
}

#viewcart {
  div.engraving {
    .engraving-view-title {
      display: none;
    }
  }
}

.page-products,
.page-product {
  &.device-mobile {
    #colorbox {
      #cboxWrapper {
        #cboxContent {
          #cboxLoadedContent {
            padding-#{$rdirection}: 0;
          }
        }
      }
    }
  }
}

.spp-engraving {
  &__engraving--form-line {
    input.engrave-choice-script {
      &::placeholder {
        position: relative !important;
        bottom: 8px;
        left: 12px;
      }
    }
  }
}

.checkout {
  &__sidebar {
    .cart-item {
      .engraving-create {
        border-bottom: 0;
      }
    }
  }
}

.cart-confirm {
  &__engrave-container {
    display: none;
  }
}
