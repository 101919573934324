$color-white: #ffffff;
$color-light-gray: #cccccc;

.store-locator {
  .local-search__search-container {
    .local-search-form {
      .product-name-filters {
        background-color: $color-white;
        border: solid 1px $color-light-gray;
        display: none;
        margin: 10px 25px;
        &.expanded {
          @include box-sizing('content-box');
          position: relative;
          margin: 10px 25px;
          border-color: $color-black;
          .store-name-filters__plus-minus {
            background-size: auto auto;
            background-position: 0 -4775px;
            height: 9px;
            width: 8px;
          }
        }
        .product-name-filters__inner {
          padding: 0 16px;
        }
        .product-name-filters__header {
          padding: 15px 0;
          text-align: center;
          cursor: pointer;
        }
        .store-name-filters__plus-minus {
          background-size: auto auto;
          background-position: 0 -7224px;
          height: 9px;
          width: 9px;
          display: inline-block;
        }
        .product-name-filters__dropdown {
          padding: 19px 0 28px;
          border-top: solid 1px #fafafa;
          display: none;
        }
        &.expanded .product-name-filters__dropdown {
          display: block;
        }
        .product-name-filters__list {
          margin: 0 0 0 14px;
        }
        .product-name-filters__item {
          margin: 10px 0;
        }
        .product-name-filters__clear {
          display: block;
          text-align: center;
          margin-top: 20px;
        }
      }
    }
  }
  &.has-filters {
    .product-name-filters {
      display: block !important;
      &.hide-product-filter {
        display: none !important;
      }
    }
    &.expanded-filters .store-results {
      margin-top: 29px;
    }
  }
  .location-info__address,
  .door-row__address {
    text-transform: uppercase;
  }
  .door-row__product,
  .location-info__product {
    font-size: 10px;
    text-transform: uppercase;
  }
  .door-row__product-title,
  .location-info__product-title {
    font-weight: bold;
  }
  .store-locator__nav-item {
    width: 100% !important;
  }
}

.gm-style {
  .gm-style-iw {
    width: auto !important;
    height: auto !important;
  }
}

.section-store-locator.device-mobile {
  .store-locator .local-search-form .store-name-filters {
    &.expanded {
      position: relative;
      width: auto;
      margin: 10px 25px;
    }
  }
}
