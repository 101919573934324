/* GNAV styling */
#cboxLoadedContent {
  .social-login.gnav {
    padding-#{$ldirection}: 20px;
    margin-#{$rdirection}: 25px;
    text-align: center;
    @media #{$medium-up} {
      text-align: #{$ldirection};
    }
    .social-login {
      &__container {
        text-align: center;
        .email-optin {
          @media #{$medium-up} {
            padding-top: 0;
          }
          p {
            font-size: 12px;
            line-height: 16px;
            text-transform: none;
            text-align: $ldirection;
          }
        }
      }
      &__terms {
        font-size: 12px;
        line-height: 16px;
        text-transform: none;
        padding-#{$rdirection}: 0px;
      }
      &__divider {
        width: 100%;
      }
    }
  }
}

.social-login {
  position: relative;
  .email-optin {
    text-transform: none;
    text-align: #{$ldirection};
    margin-top: 15px;
    letter-spacing: 0.05em;
  }
  &__container {
    padding: 0 30px;
    @media #{$medium-up} {
      margin: 0 30px;
    }
  }
  &__divider {
    width: 100%;
    position: relative;
    top: 0;
    #{$ldirection}: 0;
    &:before {
      top: 34%;
    }
    .inner {
      text-transform: uppercase;
      font-size: 12px;
      bottom: 2px;
      font-family: $akzidenz;
      letter-spacing: 0.15em;
      font-weight: 100;
    }
  }
  &__email-opt-in {
    label {
      text-transform: none;
      letter-spacing: 0;
    }
  }
  &__terms {
    padding: 10px 0;
    margin-top: 10px;
    text-align: #{$ldirection};
    p {
      font: 12px/20px $akzidenz;
      letter-spacing: 0.05em;
    }
  }
}
/* checkout */
.checkout {
  &__content {
    .social-login {
      &__container {
        padding: 0;
      }
      &__email-opt-in {
        padding-top: 10px;
      }
      &__opt-in-label {
        text-transform: none;
        letter-spacing: 0.05em;
      }
      &__divider {
        margin-top: 20px;
      }
      &__terms {
        p {
          line-height: inherit;
        }
      }
    }
  }
}
/* order confirmation */
#confirmation-panel {
  .social-login {
    padding: 0;
    @media #{$medium-up} {
      width: 60%;
      margin-left: 20px;
    }
    &__container {
      padding: 0;
    }
    &__info {
      display: block;
    }
    &__email-opt-in {
      margin-top: 15px;
    }
    &__title {
      font-size: 40px;
      text-align: center;
      border-top: 1px solid $color-light-gray;
      font-family: $optimalight;
      padding-top: 10px;
      @media #{$medium-up} {
        border-top: 2px solid $color-black;
        font-size: 50px;
      }
    }
    &__opt-in-label {
      line-height: 24px;
      font-size: 15px;
      letter-spacing: 0.05em;
      text-transform: none;
      display: inline;
    }
  }
}
/* profile preferences */
.account-utilities {
  &__section {
    .connected_accounts {
      text-decoration: none;
      cursor: text;
    }
  }
}

.social-info {
  &__sidebar {
    min-height: 315px;
    visibility: hidden;
    opacity: 0;
  }
  &__section {
    padding-top: 0;
    margin-top: 150px;
    padding-bottom: 0;
  }
  &__connect {
    display: flex;
  }
}

.device-pc {
  .sidebar-page {
    position: static;
  }
}
/* Signin popup and signin page */
#cboxLoadedContent {
  .social-login {
    &.gnav {
      padding: 0;
      margin: 0;
      .social-login__divider {
        width: 100%;
      }
      .fb-overlay-container {
        text-align: center;
      }
    }
    &__container {
      padding: 0 30px;
      @media #{$medium-up} {
        padding: 0;
      }
    }
  }
  .sign-in-component {
    &__form-content--sign-in {
      margin-top: 0;
    }
  }
}
