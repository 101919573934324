.checkout {
  .checkout__content {
    .preorder-panel {
      .viewcart-preorderheader {
        .checkout__panel-title {
          font-weight: bold;
        }
      }
      &__subhead,
      .details-link {
        color: $color-blue--estee_edit;
      }
      .details-link {
        text-decoration: underline;
      }
      &__viewcart-text {
        clear: $ldirection;
        width: 260px;
        margin-top: 20%;
      }
    }
    .preorder-panel__order-summary {
      .checkout__panel-title {
        margin-bottom: 10px;
        font-weight: bold;
      }
      .order-summary {
        &__shipping-content {
          display: inline-block;
          margin-top: 10px;
        }
        &__content {
          p {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.payment-panel {
  .securepay-preorder {
    color: $color-red;
    margin-bottom: 15px;
    a {
      color: $color-blue--estee_edit;
      text-decoration: underline;
    }
  }
}

.checkout-confirmation-page {
  .confirmation-panel {
    .preorder-text__content {
      margin-top: 10px;
      a {
        color: $color-blue--estee_edit;
        text-decoration: underline;
        text-transform: capitalize;
      }
    }
  }
}

.order-history-page {
  .order-status-table {
    .sku-brief-editorial__preorder {
      font-weight: inherit;
      text-transform: inherit;
      margin: 10px 0;
      font-size: 13px;
      color: $color-red;
    }
    .preorder-detail-item-link {
      width: 100%;
    }
  }
  .preorder-history {
    &__header {
      @include swap_direction(margin, 20px 0 15px 25px);
    }
    &__subheader {
      @include swap_direction(margin, 0 0 25px 25px);
      font-size: 15px;
      text-decoration: underline;
    }
  }
}

#preorder_detail {
  .sidebar-page {
    &__content {
      .preorder-status {
        letter-spacing: 0;
      }
      .payment-form {
        display: none;
      }
    }
  }
}

.preorder-payment {
  .payment-form {
    .preorder-payment {
      &__save-payment {
        &:before {
          content: '';
        }
      }
    }
  }
}

.preorder-payment-overlay {
  #cboxLoadedContent {
    margin: 20px 0 0;
  }
}

.preorder-gift__text {
  display: inline-block;
  margin: 13px 0;
}

#index {
  .checkout {
    &__content {
      .sign-in-panel,
      .payment-panel {
        .pre-order-button-disabled {
          color: $color-white;
          border: 1px solid $color-white;
          background: $color-light-gray;
          pointer-events: none;
        }
      }
    }
  }
}

.registration-panel {
  .register_preorder_msg {
    margin-top: 20px;
  }
}

.pre-order__info,
.register_preorder_msg,
.cart-confirm__preorder {
  color: $color-red;
}

.preorder-payment_container {
  display: flex;
  flex-wrap: wrap;
  .payment-option {
    &:nth-of-type(1) {
      order: 1;
    }
    &:nth-of-type(2) {
      order: 2;
    }
    &:nth-of-type(3) {
      order: 5;
    }
  }
  .pre-order__info {
    margin-bottom: 15px;
    &:nth-of-type(1) {
      order: 3;
    }
    &:nth-of-type(2) {
      order: 4;
    }
    &:nth-of-type(3) {
      order: 6;
    }
  }
}

.preorder-spp-container {
  .review-snippet,
  .pr-review-display {
    pointer-events: none;
    opacity: 0.6;
  }
  .product-full__price-installment {
    display: none;
  }
}

.wishlist-page {
  .sku-brief-editorial {
    &__preorder {
      text-transform: inherit;
      color: $color-red;
      font-weight: inherit;
      margin: 5px 0;
    }
  }
}
